export class UsuarioQuasar {
    public usuario: UsuarioQuasarDados;
    public loginFornecedor: string;
    public categoriaAcesso: string;
	public nivelCapacidade: string;

    public idCliente: Number;
    public siglaSistema: string;
}


export class UsuarioQuasarDados {
	public token: string; 
	public cpfCnpj: string;
    public nome: string;
    public email: string;
    public emailVerificado: boolean;
    public senha: string;
    public endereco: string;
    public telefone: string;
    public telefoneVerificado: boolean;
    public status: string;
    public enabled: boolean;

}