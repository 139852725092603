import { Component, OnInit } from '@angular/core';
import { Contexto } from '../modelo/contexto';
import { SegurancaService } from '../service/seguranca.service';
import { throws } from 'assert';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ItemSelecaoContextoQuasar } from '../contexto/selecao-contexto';

@Component({
  selector: 'app-menu0',
  templateUrl: './menu0.component.html',
})
export class Menu0Component implements OnInit {

  constructor(
    private segurancaService: SegurancaService
    ) { }

  entidade: ItemSelecaoContextoQuasar;

  ngOnInit() {
    let contexto = this.segurancaService.getLicenca();
    if(contexto != null){
      this.entidade = contexto;
    }
  }


  public redirectAppCliente(){
    this.segurancaService.sendToArbor();
  }

  public login(){
    this.segurancaService.sendToArbor();
  }

}