<bth-selecao-contexto id="selecao-contexto-entidades" placeholder-pesquisa="Qual entidade você está buscando?">

    <h4 class="mt-5" slot="cabecalho">Selecione a entidade que deseja acessar</h4>
    
    <img src="help.svg" alt="Você precisa de ajuda?" slot="sem_resultado" />
    
    <h4 slot="sem_resultado"></h4>
    
    <span slot="sem_resultado">
        
    </span>
    
    <div slot="rodape" style="text-align: right;">
        <a href="https://betha.cloud/#/liberacoes/entidade">Liberar nova entidade</a>
    </div>
    
<bth-selecao-contexto>
