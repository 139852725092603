import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs";

@Injectable({ providedIn: 'root' })
export class ErrorNotificationService {

   constructor() {
   }
   
   public error = new BehaviorSubject< Error | HttpErrorResponse | null>(null);

   setError(e: Error | HttpErrorResponse) {
      this.error.next(e);
    }

}