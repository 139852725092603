import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from 'src/app/lib/apiHttp';
import { HttpMethodApi, ServicoCode } from 'src/app/lib/apiQuasarHttp';
import { Authorization } from '@betha-plataforma/estrutura-componentes';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Entidade } from 'src/app/modelo/entidade';


@Injectable({
  providedIn: 'root'
})

export class LicencaService {
  constructor(private httpClient: ApplicationHttpClient) {
  }

  servico: ServicoCode = ServicoCode.LICENCAS;

  //Consulta dados da entidade atual
  /**
   *   --url 'https://plataforma-licencas.betha.cloud/licenses/v0.1/api/entidades/atual/' 
   *   --header 'Content-Type: application/json' \
   *   --header 'Authorization: Bearer {TOKEN}' \
   *   --header 'User-Access: {USER_ACCESS}'
   */
  getDadosEntidadeAtual(userAccess: string): Observable<Entidade> {
    return this.httpClient.fetchData({ 
      endPoint: 'entidades/atual', 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: new HttpHeaders(
        {
          Authorization: `Bearer ${this.httpClient.getAuthorization().accessToken}`,
          'Content-Type': 'application/json',
          'User-access': `${userAccess}`}
      ),
      params: null,        
      servico: this.servico });
  }

  // //Consulta dados das entidades do escopo
  // /**
  //  *   --url 'https://plataforma-licencas.betha.cloud/licenses/v0.1/api/entidades/atual/entidades?limit=20&offset=0&sort=nome%20asc&='
  //  *   --header 'Content-Type: application/json' \
  //  *   --header 'Authorization: Bearer {TOKEN}' \
  //  *   --header 'User-Access: {USER_ACCESS}'
  //  */
  getDadosEntidadeDoEscopo(): Observable<Entidade> {
    return this.httpClient.fetchData({ 
      endPoint: 'entidades/atual/entidades', 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      params: null,      
      servico: this.servico});    
  }

  // //Verifica o database vinculado a uma determinada entidade
  // /**
  //  *   --url 'https://plataforma-licencas.betha.cloud/licenses/v0.1/api/databases?entity={ID_ENTIDADE}'
  //  *   --header 'Content-Type: application/json' \
  //  *   --header 'Authorization: Bearer {TOKEN}' \
  //  *   --header 'User-Access: {USER_ACCESS}'
  //  */
  // verificarDatabaseVinculadaEntidade(accesToken:String, userAccess:String, idEntidade: String): Observable<any> {
  //   return this.fetchData({ 
  //     url: '/databases?entity='+idEntidade, 
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico ,
  //     token: accesToken,
  //     user_access: userAccess });
  // }

  // //Verifica o nome de uma entidade a partir do ID
  // /**
  //  *   --url 'https://plataforma-licencas.betha.cloud/licenses/v0.1/api/entidades/atual/entidades?limit=20&offset=0&sort=nome%20asc&='
  //  *   --header 'Content-Type: application/json' \
  //  *   --header 'Authorization: Bearer {TOKEN}' \
  //  *   --header 'User-Access: {USER_ACCESS}'
  //  */
  // getNomeEntidade(accesToken:String, userAccess:String): Observable<any> {
  //   return this.fetchData({ 
  //     url: '/entidades/atual/entidades?limit=', 
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico ,
  //     token: accesToken,
  //     user_access: userAccess });
  // }

  // //Fonte de dados para consulta de dados de entidades
  // /**
  //  *   --url 'https://plataforma-licencas-dados.betha.cloud/licenses/dados/v0.1/api/entidades?fields=esfera'
  //  *   --header 'Content-Type: application/json' \
  //  *   --header 'Authorization: Bearer {TOKEN}' \
  //  *   --header 'User-Access: {USER_ACCESS}'
  //  */
  // getDadosEntidades(accesToken:String, userAccess:String): Observable<any> {
  //   return this.fetchData({ 
  //     url: '/entidades?fields=esfera', 
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico ,
  //     token: accesToken,
  //     user_access: userAccess });
  // }

  // //Consulta a última licença a partir da entidade, database e sistema
  // /**
  //  *   --url 'https://plataforma-licencas.betha.cloud/licenses/v0.1/api/licensing?database={ID_DATABASE}&entity={ID_ENTIDADE}&system={ID_SISTEMA}'
  //  *   --header 'Content-Type: application/json' \
  //  *   --header 'Authorization: Bearer {TOKEN}' 
  //  */
  // getUltimaLicencaEntidadeDatabaseSistema(accesToken:String, idDataBase: String, idEntidade: String, idSistema: String): Observable<any> {
  //   return this.fetchData({ 
  //     url: '/licensing?database='+idDataBase+'&entity='+idEntidade+'&system='+idSistema,
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico ,
  //     token: accesToken,
  //     user_access: null });
  // }

  // //Verifica se a licença está ativa a partir da entidade, database e sistema
  // /**
  //  *   --url 'https://plataforma-licencas.betha.cloud/licenses/v0.1/api/licensing/validate?database={ID_DATABASE}&entity={ID_ENTIDADE}&system={ID_SISTEMA}'
  //  *   --header 'Content-Type: application/json' \
  //  *   --header 'Authorization: Bearer {TOKEN}' \
  //  */
  // verificarLicencaAtiva(accesToken:String, idDataBase: String, idEntidade: String, idSistema: String): Observable<any> {
  //   return this.fetchData({ 
  //     url: '/licensing/validate?database='+idDataBase+'&entity='+idEntidade+'&system='+idSistema,
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico ,
  //     token: accesToken,
  //     user_access: null });
  // }

}
