import { Component, Injectable, OnInit } from '@angular/core';
import { LicencaService } from '../service/betha/licenca.service';
import { AutorizacoesService } from '../service/betha/autorizacoes.service';
import { Licenca } from '../modelo/licencas';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorNotificationService } from './errornotification.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styles: ['p { color: red }']
})

export class ErrorComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private errorNotificationService: ErrorNotificationService
  ) { }

  public errorMessage = '';

  public ngOnInit() {
    this.errorNotificationService.error.subscribe((value) => {
      this.errorMessage = value.message;
    });
    
  }
}


