import { HttpHeaders, HttpParams } from "@angular/common/http";
import { UsuarioQuasar, UsuarioQuasarDados } from "../modelo/usuarioquasar";

export interface IHttpRequestApi {
  headers: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;

  method: HttpMethodApi,
  endPoint: string,
  servico?: ServicoCode;
  authentication:boolean;
  useracess?:boolean;
  contexto?:boolean;
};

export enum HttpMethodApi {
  POST = 1,
  GET = 2,
  PUT = 3,
  DELETE = 4,
}

export enum ServicoCode {
  ARBOR = 1,
  AUTORIZACOES = 2,
  LICENCAS = 3,
  LOGIN = 4,
  SEGURANCA = 5,
  SINAL = 6,
  USUARIOS = 7,
}

export enum HttpStatusCode {
  OK = 200,
  NO_CONTENT = 204,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
}

export const HTTP_ERROS: Record<number, string> = {
  302: "HttpErros.302",
  400: "HttpErros.400", //400 Bad Request
  401: "HttpErros.401", //401 Unauthorized
  403: "HttpErros.403", //403 Forbidden
  404: "HttpErros.404", //404 Not Found
  405: "HttpErros.405", //405 Method Not Allowed
  406: "HttpErros.406", //406 Not Acceptable
  408: "HttpErros.408", //408 Request Timeout
  409: "HttpErros.409", //409 Conflict
  500: "HttpErros.500", //500 Internal Server Error
  502: "HttpErros.502", //502 Bad Gateway
  503: "HttpErros.503", //503 Service Unavailable
  504: "HttpErros.504", //504 Gateway Timeout
};


export type BodyWrapperQuasarService<T = any> = {
  versaoRest: WsRestApiVersao;
  errorRest: WsRestApiError;
  listaObj?: T;
};

export type WsRestApiVersao = {
  servico: string | ((key: string | number) => string);
  metodo: string;
  versao: Number;
};

export type WsRestApiError = {
  status?: HttpStatusCode;
  message?: string;
  errors?: Array<string>;
};


export interface WsLoginQuasar {
  usuario: UsuarioQuasarDados;
  cliente: WsCliente;
  loginFornecedor: WsLoginFornecedor;

}

export interface WsLoginFornecedor {
  login: string;
  nome: string;
  cpfCnp: string;
  email: string;
}

export interface WsCliente {
	idCliente: Number;
	nome: string;
	cnpj: string;
	codTom: string;
	codIbge: string;
	tipoDeVinculo: string;
	uf: string;
}