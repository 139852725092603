import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Inject, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModal, NgbModalConfig, NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { Menu0Component } from './menu/menu0.component';
import { ContextoComponent } from './contexto/contexto.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutorizacoesService } from './service/betha/autorizacoes.service';
import { ApplicationHttpClient, applicationHttpClientCreator } from './lib/apiHttp';
import { LicencaService } from './service/betha/licenca.service';
import { ErrorComponent } from './error/error.component';
import { GlobalErrorHandler } from './error/errorglobalhandler.service';
import { CallbackComponent } from './authentication/callback';
import { EntidadesComponent } from './entidades/entidades';
import { GerenciandoAcessosComponent } from './gerenciando/gerenciandoacessos.component';
import { SegurancaService } from './service/seguranca.service';

@NgModule({
  declarations: [AppComponent, Menu0Component, ContextoComponent, ErrorComponent, CallbackComponent, EntidadesComponent, GerenciandoAcessosComponent],
  imports: [BrowserModule, ReactiveFormsModule, HttpClientModule, AppRoutingModule, NgbModule, FormsModule],
  providers: [AutorizacoesService, LicencaService, NgbModalConfig, NgbModal, SegurancaService,
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient]
    },
    {provide: ErrorHandler, useClass: GlobalErrorHandler},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
