import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Menu0Component } from './menu/menu0.component';
import { ContextoComponent } from './contexto/contexto.component';
import { ErrorComponent } from './error/error.component';
import { CallbackComponent } from './authentication/callback';
import { PageNotFoundComponent } from './error/pagenotfound.component';
import { EntidadesComponent } from './entidades/entidades';
import { GerenciandoAcessosComponent } from './gerenciando/gerenciandoacessos.component';
import { Page403Component } from './error/page403.componen';


const routes: Routes = [
  { path: '',  component: Menu0Component },
  { path: 'access_token',  component: CallbackComponent },
  { path: 'callback',  component: CallbackComponent },
  { path: 'contexto', component: ContextoComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'entidades/:contexto', component: EntidadesComponent },
  { path: 'gerenciando', component: GerenciandoAcessosComponent },
  { path: '403', component: Page403Component},
  { path: '**', component: PageNotFoundComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
      { useHash: true, 
        relativeLinkResolution: 'legacy'
      },
    )],
  exports: [RouterModule],
  
})
export class AppRoutingModule { }
