import { Injectable } from '@angular/core';
import { Usuario } from '../../modelo/usuario';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpMethodApi, ServicoCode } from 'src/app/lib/apiQuasarHttp';
import { AcessoUsuario } from 'src/app/modelo/acessousuario';
import { ApplicationHttpClient } from 'src/app/lib/apiHttp';


@Injectable({
  providedIn: 'root'
})

export class UsuarioService {
  servico: ServicoCode = ServicoCode.USUARIOS;

  constructor(private httpClient: ApplicationHttpClient) {
  }

  //Consultar usuários
  /**
   *   --url 'https://plataforma-usuarios.betha.cloud/usuarios/v0.1/api/usuarios/?filter=id%3D'\''kaio.stricker'\'''
   *   --header 'Content-Type: application/json' \
   *   --header 'Authorization: Bearer {TOKEN}' \
   */
  // getUsuarios(accesToken:String, filtros: String): Observable<any> {
  //   return this.httpClient.fetchData({ 
  //     endPoint: '/usuarios/?filter='+{filtros}, 
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico ,
  //     user_access: null });
  // }

  //Consultar usuários por id
  /**
   *   --url 'https://plataforma-usuarios.betha.cloud/usuarios/v0.1/api/usuarios/{ID_USUARIO}'
   *   --header 'Content-Type: application/json' \
   *   --header 'Authorization: Bearer {TOKEN}' \
   */
  getInformacaoUsuario(usuarioId: String): Observable<Usuario> {
    return this.httpClient.fetchData({ 
      endPoint: 'usuarios/'+usuarioId, 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      params: null,
      useracess: false,
      contexto: false,
      servico: this.servico});
  }

  // //Consultar imagem vinculada ao usuário
  // /**
  //  *   --url 'https://plataforma-usuarios.betha.cloud/usuarios/v0.1/api/usuarios/{ID_USUARIO}/photo'
  //  *   --header 'Content-Type: application/json' \
  //  *   --header 'Authorization: Bearer {TOKEN}' \
  //  */
  // getFotoUsuario(accesToken:String, usuarioId: String): Observable<any> {
  //   return this.fetchData({ 
  //     url: '/usuarios/'+{usuarioId}+'/photo', 
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico ,
  //     token: accesToken,
  //     user_access: null });
  // }


  //  //Consultar usuário verificado
  // verified(usuarioId: String): Observable<any> {
  //   return this.fetchData({ 
  //     url: '/usuarios/'+{usuarioId}+'/verified', 
  //     method: HttpMethodApi.GET, 
  //     body: null,
  //     headers: null,
  //     servico: this.servico });
  // }
}
