import { Component, OnInit } from '@angular/core';
import { AutorizacoesService } from '../service/betha/autorizacoes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Licenca } from '../modelo/licencas';
import { environment } from 'src/environments/environment';
import { LicencaService } from '../service/betha/licenca.service';
import { ItemSelecaoContextoQuasar } from './selecao-contexto';

@Component({
  selector: 'app-contexto',
  templateUrl: './contexto.component.html',
})
export class ContextoComponent implements OnInit {
  IDSISTEMA: Number;

  constructor(
    private autorizacoesService: AutorizacoesService,
    private licencaService: LicencaService,
    private route: ActivatedRoute,
    private router: Router     
    ) { 

      this.IDSISTEMA = environment.app.betha.sistemaid;
    }


  ngOnInit() {
    //Limpar Licenca e contexto localStorage
    localStorage.removeItem('licenca');
    localStorage.removeItem('contexto');

    var itens: ItemSelecaoContextoQuasar[] = [];

    this.autorizacoesService.getAcessosDoUsuario<Licenca[]>().subscribe( data => {
      if(data){
        for (var licenca of data) {
          if(licenca.system == this.IDSISTEMA){
            this.licencaService.getDadosEntidadeAtual(licenca.licenseId).subscribe( entidade => {
              if(entidade){
                  itens.push({ id: licenca.licenseId, descricao: entidade.nome, 
                    context: licenca.context, icone: 'ship-wheel', complemento: entidade.municipio.nome, 
                    iconeStatus: !licenca.accepted ? 'medal' : 'lock', 
                    iconeStatusTitle: !licenca.accepted ? '' : 'Licença bloqueada' ,
                    redirectArbor: !licenca.admin});
              }
            });
          }
        }
      }
    });

    

    var selecaoContexto:HTMLBthSelecaoContextoElement = document.querySelector('bth-selecao-contexto[id=selecao-contexto-entidades]');
    
    selecaoContexto.buscar = function buscar() {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(itens)
        }, 1500);
      })
    };
    
    selecaoContexto.selecionar = function selecionar(item: ItemSelecaoContextoQuasar) {
      //console.log('Selecionado entidade', item);
      
      
      //saveLicenca;
      const data = JSON.stringify(item);
      //localStorage -> Persiste no browser do usuario
      //sessionStorage -> Fechou a aba do browser os dados sao perdidos
      //Salvar como json para possibilitar salvar vários objetos
      localStorage.setItem('licenca', data);

      //Enviar para rota de entidades entidades/:contexto
      window.location.href = '#/entidades/'+item.context; 
    };
  }
}


