import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpMethodApi, ServicoCode } from '../lib/apiQuasarHttp';
import { ApplicationHttpClient } from '../lib/apiHttp';
import { UsuarioQuasar } from '../modelo/usuarioquasar';

@Injectable({
  providedIn: 'root'
})

export class ArborService {
  constructor(private httpClient: ApplicationHttpClient) {}

  servico: ServicoCode = ServicoCode.ARBOR;

  concederAcessoUsuario(acessoUsuario :UsuarioQuasar): Observable<any> {
    let urlAppCliente = this.httpClient.getContexto().urlClienteQuasar;

    return this.httpClient.fetchData({ 
      endPoint: urlAppCliente+ '/service/acesso/conceder', 
      authentication: true,
      useracess:true,
      method: HttpMethodApi.POST, 
      body: JSON.stringify(acessoUsuario),
      headers: null,
      params: null,  
      servico: this.servico});    
  }

  removerAcessoUsuario(acessoUsuario :UsuarioQuasar): Observable<any> {
    let urlAppCliente = this.httpClient.getContexto().urlClienteQuasar;

    return this.httpClient.fetchData({
      endPoint: urlAppCliente+ '/service/acesso/remover', 
      authentication: true,
      method: HttpMethodApi.POST,
      body: JSON.stringify(acessoUsuario),
      headers: null,
      params: null,  
      useracess:true,
      servico: this.servico});    
  }
}
