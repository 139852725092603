import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Usuario } from '../modelo/usuario';
import { BodyWrapperQuasarService, HttpMethodApi, ServicoCode, WsLoginQuasar } from '../lib/apiQuasarHttp';
import { ApplicationHttpClient } from '../lib/apiHttp';
import { Contexto } from '../modelo/contexto';
import { WebToken } from '../modelo/webtoken';
import { ItemSelecaoContextoQuasar } from '../contexto/selecao-contexto';

@Injectable({
  providedIn: 'root'
})

export class SegurancaService {
  constructor(private httpClient: ApplicationHttpClient) {}

  servico: ServicoCode = ServicoCode.SEGURANCA;

  loginQuasar(): Observable<BodyWrapperQuasarService<WsLoginQuasar>> {
    return this.httpClient.fetchData({ 
      endPoint: 'betha/loginQuasar', 
      authentication: false,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      params: new HttpParams({ 
        fromObject: { 'access_token': this.getWebToken().access_token,
                      'state': this.getWebToken().state,
                      'provedorOAuth': 'BETHA'}
      }),
      servico: this.servico});
  }

  getUrlAppCliente(): Observable<BodyWrapperQuasarService> {
    return this.httpClient.fetchData({ 
      endPoint: 'betha/urlLoginAppCliente', 
      authentication: false,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      params: new HttpParams({ 
        fromObject: { 'p': this.getParametos()}
      }),
      servico: this.servico});
  }

  getUrlRedirectLoginBetha(): Observable<BodyWrapperQuasarService> {
    return this.httpClient.fetchData({ 
      endPoint: 'betha/urlLogin', 
      authentication: false,
      method: HttpMethodApi.GET, 
      headers: null,
      body: null,
      params: new HttpParams({ 
        fromObject: { 'p': this.getParametos()}
      }),
      servico: this.servico});
  }

  getParametos() {
    if(this.httpClient.getContexto()){
      return btoa(
        's='+`${environment.app.quasar.sistemasigla}`
       +'&d='+this.httpClient.getContexto().database
       +'&e='+this.httpClient.getContexto().entity
       +'&c='+`${environment.authentication.clientId}`);  
    }else{
      throw Error('Selecione o contexto!');
    }
  }

  getContexto(): Contexto {
    return this.httpClient.getContexto();
  }

  getUserAcess(): string {
    return this.httpClient.getUserAcess();
  }

  getLicenca(): ItemSelecaoContextoQuasar {
    return this.httpClient.getLicenca();
  }

  getWebToken(): WebToken {
    return this.httpClient.getWebToken();
  }

  isAuthenticated(): Boolean { 
    return this.httpClient.isAuthenticated();
  }  

  isContextoSelecionado(): Boolean { 
    return this.httpClient.isContextoSelecionado();
  }  
  
  isLicencaSelecionada(): Boolean { 
    return this.httpClient.isLicencaSelecionada();
  }  

  isUserAcessActive(): Boolean { 
    return this.httpClient.isUserAcessActive();
  }  
  

  sendToLoginBetha(){
    this.getUrlRedirectLoginBetha().subscribe( data => {
      //console.log('data ' + this.segurancaService.getContexto());
      if(data && data.listaObj){
         //Enviar para o login
         window.location.href = data.listaObj[0];
         return;
      }else if(data && data.errorRest){

        if(this.getContexto() == null){
          //Enviar para o portal da betha
          window.location.href = environment.app.betha.logincloud;
          return;
        }else{
          throw Error(data.errorRest.message);
        }
      }else{
        throw Error('Erro ao realizar o login!');
      }
    });
  }

  sendToArbor(){
    let urlAppCliente = this.httpClient.getContexto().urlClienteQuasar;
    //console.log('data ' + this.segurancaService.getContexto());
    if(urlAppCliente){
        //Enviar para o login do Arbor
        openAppCliente(urlAppCliente, '/service/autenticacao/loginOAuth', this.getWebToken());
        return;
    }else if(this.getContexto() == null){
        //Enviar para o portal da betha
        window.location.href = environment.app.betha.logincloud;
    }else{
      throw Error('Sistema não configurado para o contexto selecionado!');
    }


    function openAppCliente(link: string, servico: string, webtoken: WebToken) {
      var form = document.createElement("form");
      form.target = "view";
      form.method = "POST";
      form.action = link + servico;
      var params = {
        "token": webtoken.wsLoginQuasar.usuario.token
      };
  
      for (var i in params) {
          if (params.hasOwnProperty(i)) {
            var input = document.createElement('input');
            input.type = 'hidden';
            input.name = i;
            input.value = params[i];
            form.appendChild(input);
          }
      }
      document.body.appendChild(form);
      form.submit();
      window.open('','view');
    }
  }
  
}
