
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contexto } from '../modelo/contexto';
import { SegurancaService } from '../service/seguranca.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-entidades',
  templateUrl: './entidades.html'
})
export class EntidadesComponent implements OnInit{

    constructor(
        private segurancaService: SegurancaService,
        private route: ActivatedRoute,
        private router: Router 
      ) { }

    //https://vishalwaman.medium.com/storing-encryptions-keys-in-angular-vuejs-reactjs-cee6c5306a5e
    //https://dev.to/zokizuan/mastering-secure-local-storage-in-angular-with-crypto-js-k8a
    ngOnInit() {
        let contextoBase64 = this.route.snapshot.params.contexto;
        //console.log('contextoBase64: '+contextoBase64);
        if(contextoBase64){
          let contextoString = atob(contextoBase64);
          if(contextoString){
            const contextoArray = contextoString.split(',');
            const database = contextoArray[0].split(':');
            const entity = contextoArray[1].split(':');
            //Salvando o contexto sem a URL do Cliente
            this.saveContexto(new Contexto('', database[1],entity[1]));

            //Recuperar a URL do sistema Quasar do cliente
            this.segurancaService.getUrlAppCliente().subscribe( data => {
              if(data && data.listaObj){
                //console.log(data.listaObj[0]);
                //Salvando o contexto com a URL do Cliente
                this.saveContexto(new Contexto(data.listaObj[0], database[1],entity[1]));
                this.goToHome();
              }else{
                throw Error('Sistema não configurado para o contexto selecionado!');
              }
            });
          }
        }
    }


    saveContexto(contexto: Contexto) {
      const data = JSON.stringify(contexto);
  
      //localStorage -> Persiste no browser do usuario
      //sessionStorage -> Fechou a aba do browser os dados sao perdidos
      //Salvar como json para possibilitar salvar vários objetos
      //console.log(data);
      localStorage.setItem('contexto', data);
    }


    goToHome() {
      //console.log('EntidadesComponent.isAuthenticated: '+this.segurancaService.isAuthenticated());
      if(!this.segurancaService.isAuthenticated()){
        //Login
        this.sendToLoginBetha();
      }else if(this.segurancaService.isLicencaSelecionada() && this.segurancaService.getLicenca().redirectArbor){
        this.sendToArbor();
      }else{
        //Home
        this.router.navigate([''], { relativeTo: this.route }) .then(() => {
          window.location.reload();
        });
      }
    }


    public sendToLoginBetha(){
      this.segurancaService.sendToLoginBetha();
    }

    public sendToArbor(){
      this.segurancaService.sendToArbor();
    }
}


