import { Contexto } from "./contexto";

//Definicao de classe publica
export class AcessoUsuario {
    constructor(
        public user: string,
        public admin: boolean,
        public context: Contexto
    ) { }
}
