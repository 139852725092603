import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AutorizacoesService } from '../service/betha/autorizacoes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Licenca } from '../modelo/licencas';
import { NgbModal, NgbModalConfig, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from '../service/betha/usuarios.service';
import { Usuario } from '../modelo/usuario';
import { SegurancaService } from '../service/seguranca.service';
import { WebToken } from '../modelo/webtoken';
import { AcessoUsuario } from '../modelo/acessousuario';
import { Contexto } from '../modelo/contexto';
import { catchError, map, subscribeOn } from 'rxjs/operators';
import { ArborService } from '../service/arbor.service';
import { UsuarioQuasar, UsuarioQuasarDados } from '../modelo/usuarioquasar';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gerenciandoacessos',
  templateUrl: './gerenciandoacessos.component.html',
  styleUrls:  ['./gerenciandoacessos.component.scss'],
})

//https://pictogrammers.com/library/mdi/icon/select-search/
export class GerenciandoAcessosComponent implements OnInit {

  public pesquisaUsuario: string;
  public licencas: Licenca[] = [];
  public usuarioAutorizar: Usuario;
  public termAccepted: Boolean;
  public modalAddUsuarioRef:any;
  public usuarioNaoEncontrado: Boolean;

  constructor(
    private autorizacoesService: AutorizacoesService,
    private usuarioService: UsuarioService,
    private segurancaService: SegurancaService,
    private arborService: ArborService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    config: NgbModalConfig,
    ) { 
      config.backdrop = 'static';
      config.keyboard = false;
    }

  ngOnInit() {
    if(!this.segurancaService.isContextoSelecionado() || !this.segurancaService.isUserAcessActive()){
      this.goToSelectContexto();
    }else{
      this.recuperarUsuarios();
    }
  }

  private recuperarUsuarios() {
    this.autorizacoesService.getUsuariosComAcessoAoSistema<Licenca>().subscribe(data => {
      if (data) {
        this.licencas = data.content;
      }
    });
  }

  getAccessToken(): string {
    return this.getWebToken().access_token;
  }

  getWebToken(): WebToken {
    return this.segurancaService.getWebToken();
  }

  getContexto(): Contexto {
    return this.segurancaService.getContexto();
  }

  searchUsuario(){
    this.usuarioAutorizar = null;
    this.usuarioNaoEncontrado = true;
    this.usuarioService.getInformacaoUsuario(this.pesquisaUsuario).subscribe( usuario => {
       if(usuario){
        this.usuarioAutorizar = usuario;
        this.usuarioNaoEncontrado = false;
       }
    });
  }  

  public addAccess(){
    let acessoUsuario = new AcessoUsuario(this.usuarioAutorizar.id, false, this.getContexto());
    this.autorizacoesService.concederAcessoUsuario(acessoUsuario).subscribe( response => {
      this.addAccessArbor(this.usuarioAutorizar);
      this.recuperarUsuarios();
    });
    this.modalAddUsuarioRef.close(); 
  }
  
  public addAccessArbor(usuarioAutorizar: Usuario) {
    let usuarioQuasarDados = new UsuarioQuasarDados();
    usuarioQuasarDados.nome = usuarioAutorizar.name;
    usuarioQuasarDados.cpfCnpj = usuarioAutorizar.cpf;
    usuarioQuasarDados.email = usuarioAutorizar.email;
    usuarioQuasarDados.telefone = usuarioAutorizar.cellPhone;


    let usuarioQuasar = new UsuarioQuasar();
    usuarioQuasar.categoriaAcesso = environment.app.config.seguranca.categoriaAcesso;
    usuarioQuasar.nivelCapacidade = environment.app.config.seguranca.nivelCapacidade;
    usuarioQuasar.loginFornecedor = usuarioAutorizar.id;
    usuarioQuasar.idCliente = this.getWebToken().wsLoginQuasar.cliente.idCliente;
    usuarioQuasar.usuario = usuarioQuasarDados;

    this.arborService.concederAcessoUsuario(usuarioQuasar).toPromise();
    this.usuarioAutorizar = null;
  }

  public removeAccess(licenca: Licenca){
    //console.log('removeAccess: '+licenca.id);
    this.autorizacoesService.removerAcessoUsuario(licenca.id).subscribe( response => {
      this.removeAccessArbor(licenca);
      this.recuperarUsuarios();
    });
    this.modalAddUsuarioRef.close(); 
  }

  public removeAccessArbor(licenca: Licenca) {
  let usuarioQuasar = new UsuarioQuasar();
    usuarioQuasar.categoriaAcesso = environment.app.config.seguranca.categoriaAcesso;
    usuarioQuasar.nivelCapacidade = environment.app.config.seguranca.nivelCapacidade;
    usuarioQuasar.loginFornecedor = licenca.user;
    usuarioQuasar.idCliente = this.getWebToken().wsLoginQuasar.cliente.idCliente;

    this.arborService.removerAcessoUsuario(usuarioQuasar).toPromise();
  }
  
  
  public isUsuarioNaoEncontrado(): Boolean { 
    if(this.usuarioNaoEncontrado){
      return this.usuarioNaoEncontrado;
    }
    return false;
  }

  public isUsuarioJaCadastrado(): Boolean { 
    if(this.usuarioAutorizar){
      for (var licenca of this.licencas) {
        if(licenca.user === this.usuarioAutorizar.id){
          return true;
        }
      }
    }
    return false;
  }

  open(content) {
		this.modalAddUsuarioRef = this.modalService.open(content);
	}


  goToSelectContexto() {
    this.router.navigate(['/contexto'], { relativeTo: this.route });
  }
}


