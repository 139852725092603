<bth-app menu-vertical #app>
  <bth-marca-produto slot="menu_marca_produto" produto="Fiscalização Urbanas" exibir-produtos #marcaProduto> </bth-marca-produto>
  <bth-utilitarios slot="menu_ferramentas" #utilitarios></bth-utilitarios>
  <bth-conta-usuario slot="menu_ferramentas" usuario="" nome="" foto-url="" #contaUsuario> </bth-conta-usuario>

  <main slot="container_aplicacao" class="container-fluid mt-2">
    <router-outlet></router-outlet>
    <app-callback></app-callback>
  </main>
</bth-app>
