import { Component, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router"
import { Subscription } from 'rxjs';

import { Authorization, AuthorizationConfig, OpcaoMenu, Utilitario, OpcaoMenuSelecionadaEvent, OpcaoUtilitarioSelecionadaEvent } from '@betha-plataforma/estrutura-componentes';
import { SegurancaService } from './service/seguranca.service';
import { Usuario } from './modelo/usuario';
import { UsuarioService } from './service/betha/usuarios.service';
import { AutorizacoesService } from './service/betha/autorizacoes.service';
import { WebToken } from './modelo/webtoken';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('app', { static: true }) appElement: ElementRef<HTMLBthAppElement>;
  @ViewChild('marcaProduto', { static: true }) marcaProdutoElement: ElementRef<HTMLBthMarcaProdutoElement>;
  @ViewChild('utilitarios', { static: true }) utilitariosElement: ElementRef<HTMLBthUtilitariosElement>;
  @ViewChild('contaUsuario', { static: true }) contaUsuarioElement: ElementRef<HTMLBthContaUsuarioElement>;

  private opcoesMenu: Array<OpcaoMenu> = [
    { id: 'Menu0', descricao: 'Visão geral', icone: 'home', rota: '/', possuiPermissao: true }
  ];

  private opcoesUtilitarios: Array<Utilitario> = [
    { nome: 'Gerenciador de Acessos', rota: '/gerenciando', icone: 'key-variant', possuiPermissao: true, },  
    { nome: 'Seleção de contexto', rota: '/contexto', icone: 'select-search', possuiPermissao: true },
  ]

  constructor(
    public ngZone: NgZone, 
    public router: Router,
    private autorizacoesService: AutorizacoesService,
    private segurancaService: SegurancaService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {
    //console.log('ngOnInitngOnInitngOnInit');
    this.configurarApp();
    this.configurarMarcaProduto();
    if(this.isAuthenticated()){
      this.autorizacoesService.getInformacaoDoUsuario().subscribe(usuario => {
        this.configurarContaUsuario(usuario);
     });

     this.configurarUtilitarios();
    }
  }

  ngOnDestroy() {
  }


  isUrl(text) {
    try {
      new URL(text);
      return true;
    } catch {
      return false;
    }
  }

  public isAuthenticated() {
    return this.segurancaService.isAuthenticated();
  }

  private configurarApp() {
    this.appElement.nativeElement.opcoes = this.opcoesMenu;

    this.appElement.nativeElement.addEventListener('opcaoMenuSelecionada', (event: CustomEvent<OpcaoMenuSelecionadaEvent>) => {
      this.ngZone.run(() => {
        this.router.navigate([event.detail.rota]);
      });
    }
    );

    this.setMenuAtivoPorRota(this.router.url);

    this.router.events.subscribe(routeEvent => {
      if (routeEvent instanceof NavigationEnd) {
        const route: NavigationEnd = routeEvent;
        if (route.url === '/contexto') {
          //this.appElement.nativeElement.opcoes = [];
          this.appElement.nativeElement.opcoes = this.opcoesMenu;
        } else {
            this.appElement.nativeElement.opcoes = this.opcoesMenu;
        }
        this.setMenuAtivoPorRota(route.url);
      }
    });
  }

  private setMenuAtivoPorRota(rota: string) {
    this.opcoesMenu.forEach(opcaoMenu => {
      if (opcaoMenu.rota === rota) {
        this.appElement.nativeElement.setMenuAtivo(opcaoMenu.id);
      }

      if (opcaoMenu.submenus) {
        opcaoMenu.submenus.forEach(submenu => {
          if (rota.includes(submenu.rota)) {
            this.appElement.nativeElement.setMenuAtivo(submenu.id);
          }
        });
      }
    })
  }

  private configurarMarcaProduto() {
    this.marcaProdutoElement.nativeElement.authorization = this.getAuthConfig();
  }

  private async configurarContaUsuario(usuario: Usuario) {
    await customElements.whenDefined('bth-conta-usuario');

    if(usuario){
      this.contaUsuarioElement.nativeElement.usuario = usuario.id;
      this.contaUsuarioElement.nativeElement.nome = usuario.name;
      this.contaUsuarioElement.nativeElement.fotoUrl = usuario.photo;
    }
    this.contaUsuarioElement.nativeElement.addEventListener('logout', () => {
      this.autorizacoesService.logout();
      //this.router.navigate([''], { relativeTo: this.route });
      //window.location.reload();
      window.location.href = environment.api.url_betha_logout;
    });
  }

  public getAuthConfig = (): AuthorizationConfig => ({
    getAuthorization: this.autorizacoesService.getAuthorization,
    handleUnauthorizedAccess: this.autorizacoesService.handleUnauthorizedAccess
  })

  private configurarUtilitarios() {
    this.utilitariosElement.nativeElement.utilitarios = this.opcoesUtilitarios;

    this.utilitariosElement.nativeElement.addEventListener('opcaoUtilitarioSelecionada', (event: CustomEvent<OpcaoUtilitarioSelecionadaEvent>) => {
      this.ngZone.run(() => {
        if (this.isUrl(event.detail.rota)) {
          window.open(event.detail.rota, '_blank')
          return;
        }

        this.router.navigate([event.detail.rota]);
      });
    });
  }
}
