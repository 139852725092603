import { Component } from '@angular/core';

@Component({
  selector: 'app-page403',
  templateUrl: './page403.component.html'
})
export class Page403Component {
  
}


