import { HttpErrorResponse } from "@angular/common/http"
import { ErrorHandler, Injectable } from "@angular/core"
import { SegurancaService } from "../service/seguranca.service";
import { ErrorNotificationService } from "./errornotification.service";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler extends ErrorHandler {

   constructor(
      private segurancaService: SegurancaService, private errorNotificationService: ErrorNotificationService) {
       super();
   }
   
   public handleError(error: Error | HttpErrorResponse) {
      //console.log('handleError.name: '+error.name);
      //console.log('handleError.message: '+error.message);
      //console.log('handleError.status: '+(error as HttpErrorResponse).status);
      this.errorNotificationService.setError(error);
      if(!this.segurancaService.isContextoSelecionado()){
         window.location.href = environment.app.betha.logincloud;
         return;    
      }else if (this.isTypeHttpErrorEnviarParaLogin(error)) {
         //console.log('this.isTypeHttpErrorEnviarParaLogin(error)');
         this.segurancaService.getUrlRedirectLoginBetha().subscribe( data => {
            if(data && data.listaObj){
               window.location.href = data.listaObj[0];
            }else if(data.errorRest){
               this.errorNotificationService.setError(Error(data.errorRest.message));
               window.location.href = '/#/error';
            }
          });
          return;
      }else if (this.isTypeHttpErrorAcessoNegado(error)) {
         window.location.href = '/#/403';
         return;
      }

      if(!this.isTypeHttpErrorNaoFazerNada(error)){
        window.location.href = '/#/error';
      }
   }

   isTypeHttpErrorEnviarParaLogin(e: any): e is HttpErrorResponse {
      if(this.segurancaService.isAuthenticated()){
         //Usuario já autenticado
         return false;
      }

      let status: Number = (e as HttpErrorResponse).status;
      //console.log('handleError.status: '+status);
      
      //401 Unauthorized
      return (status == 401);
   }

   isTypeHttpErrorAcessoNegado(e: any): e is HttpErrorResponse {
      let status: Number = (e as HttpErrorResponse).status;
     
      //403 Forbidden
      return (status == 403);
   }


   isTypeHttpErrorNaoFazerNada(e: any): e is HttpErrorResponse {
      let status: Number = (e as HttpErrorResponse).status;
      //404 NotFound
      return status == 404;
   }
}