export const environment = {
  production: false,
  authentication: {
    scope: 'user-accounts.suite',
    clientId: '3af7e8b9-7fa0-43ba-835a-2e397f3922b6'
  },
  app: {
    quasar: {
      sistemasigla: 'ARBOR',
    },
    betha: {
      logincloud: 'https://betha.cloud',
      sistemaid: 366,
    },
    config: {
      seguranca: {
        categoriaAcesso: 'ANALISTA',
        nivelCapacidade: 'NIVEL_1',
      }
    }
  },
  api: {
    url_quasar_seguranca: 'https://sistemas.des.quasar.tec.br/seguranca/service',

    url_betha_login: 'https://login.betha.cloud',
    url_betha_logout: 'https://login.betha.cloud/servicelogin/logout?continue=https://plataforma-oauth.betha.cloud/auth/oauth2/authorize?client_id=3af7e8b9-7fa0-43ba-835a-2e397f3922b6%26response_type=token%26redirect_uri=https://betha.cloud/auth/callback.html%26scope=user-accounts.suite%26bth_ignore_origin=true',
    url_betha_usuarios: 'https://plataforma-usuarios.betha.cloud/usuarios/v0.1/api',
    url_betha_autorizacoes: 'https://plataforma-autorizacoes.betha.cloud/user-accounts/v0.1/api',
    url_betha_licencas: 'https://plataforma-licencas.betha.cloud/licenses/v0.1/api'
  }
};