
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebToken } from '../modelo/webtoken';
import { SegurancaService } from '../service/seguranca.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.html'
})

//https://www.baeldung.com/rest-api-spring-oauth2-angular
//https://www.baeldung.com/rest-api-spring-oauth2-angular
export class CallbackComponent  implements OnInit{

  constructor(
    private segurancaService: SegurancaService,
    private route: ActivatedRoute,
    private router: Router 
  ) { }

  ngOnInit() {
    let indexOfAccessToken = window.location.href.indexOf('access_token=');
    //console.log(indexOfAccessToken);
    if(indexOfAccessToken != -1){
      var tokenString = window.location.href.substring(indexOfAccessToken);
      if(tokenString.indexOf("error") == -1){
        this.saveToken(tokenString);
        this.goToContexto();
      }else{
        this.goToError();
      }
    }
  }

  saveToken(tokenString: string) {
    const urlParams = new URLSearchParams(tokenString);
    const entries = urlParams.entries(); //returns an iterator of decoded [key,value] tuples
    const params = paramsToObject(entries); //{abc:"foo",def:"[asf]",xyz:"5"}
    
    //Salvar os dados do login na Betha
    const dataJSON = JSON.stringify(params);
    
    //Salvar a data de expiracao do token
    let webTokenBetha = JSON.parse(dataJSON);
    if(webTokenBetha.expires_in){
      webTokenBetha.expires_at = Date.now() + (Number(webTokenBetha.expires_in) * 1000);
    }
    localStorage.setItem('token', JSON.stringify(webTokenBetha));
    
    this.segurancaService.loginQuasar().subscribe( data => {
      if(data){
        //Salvar os dados do login na Quasar
        if(data.listaObj){
          let webTokenQuasar = this.segurancaService.getWebToken();
          webTokenQuasar.wsLoginQuasar = data.listaObj[0];
          localStorage.setItem('token', JSON.stringify(webTokenQuasar));
        }else  if(data.errorRest){
          throw Error(data.errorRest.message);
        }
      }
    });

    function paramsToObject(entries) {
      const result = {}
      for(const [key, value] of entries) { // each 'entry' is a [key, value] tupple
        result[key] = value;
      }
      return result;
    }
  }

  goToContexto() {
    this.router.navigate(['contexto'], { relativeTo: this.route });
  }

  goToError() {
    this.router.navigate(['error'], { relativeTo: this.route });
  }
}