import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HttpMethodApi, IHttpRequestApi, ServicoCode} from './apiQuasarHttp';
import { environment } from 'src/environments/environment';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core'; 
import { Authorization } from '@betha-plataforma/estrutura-componentes';
import { WebToken } from '../modelo/webtoken';
import { Contexto } from '../modelo/contexto';
import { Licenca } from '../modelo/licencas';
import { ItemSelecaoContextoQuasar } from '../contexto/selecao-contexto';

 export function applicationHttpClientCreator(http: HttpClient) {
    return new ApplicationHttpClient(http);
 }

 @Injectable({
  providedIn: 'root',
 })
 export class ApplicationHttpClient {
  BASE_URL_QUASAR_SEGURANCA: string;

  BASE_URL_BETHA_LOGIN: string;
  BASE_URL_BETHA_USUARIOS: string;
  BASE_URL_BETHA_AUTORIZACOES: string;
  BASE_URL_BETHA_LICENCAS: string;

  constructor(private httpClient: HttpClient) {
    this.BASE_URL_QUASAR_SEGURANCA = `${environment.api.url_quasar_seguranca}/`;
    
    this.BASE_URL_BETHA_LOGIN = `${environment.api.url_betha_login}/`;
    this.BASE_URL_BETHA_USUARIOS = `${environment.api.url_betha_usuarios}/`;
    this.BASE_URL_BETHA_AUTORIZACOES = `${environment.api.url_betha_autorizacoes}/`;
    this.BASE_URL_BETHA_LICENCAS = `${environment.api.url_betha_licencas}/`;
  }

  getApi(
    servico: ServicoCode | undefined
  ): string {
    let fetchUrl = null;
    if (servico == ServicoCode.SEGURANCA) {
      fetchUrl = this.BASE_URL_QUASAR_SEGURANCA;
    } else if (servico == ServicoCode.AUTORIZACOES) {
      fetchUrl = this.BASE_URL_BETHA_AUTORIZACOES;
    } else if (servico == ServicoCode.LICENCAS) {      
      fetchUrl = this.BASE_URL_BETHA_LICENCAS;
    } else if (servico == ServicoCode.USUARIOS) {      
      fetchUrl = this.BASE_URL_BETHA_USUARIOS;
    } else if (servico == ServicoCode.LOGIN) {      
      fetchUrl = this.BASE_URL_BETHA_LOGIN;
    } else if (servico == ServicoCode.ARBOR) {      
      //Não existe URL unica, cada cliente (Contexto) tem uma URL especifica
      //A URL completa do serviço é passada na camada de servico (arbor.service.ts)
      fetchUrl = '';
    }    
    return fetchUrl;
  }

  getAuthorization(): Authorization {
      return {
        accessId: '',
        accessToken: `${this.getWebToken()?.access_token}`,
        userAccess: `${this.getUserAcess()}`,
        systemId: environment.app.betha.sistemaid
      };
  }

  getContexto(): Contexto {
    const dataLoad = localStorage.getItem('contexto');
    if (dataLoad) {
      //Converter o JSON salvo para a lista
      let contexto: Contexto = JSON.parse(dataLoad);
      //console.log(contexto.database + ' ' + contexto.entity);
      return contexto;
    }
    return null;
  }


  public limparLocalStorage(){
    localStorage.clear();
  }

  public checkCredentials() {
    return this.getWebToken() != null &&  this.isAuthenticated();
  } 

  isAuthenticated(): Boolean { 
    if(this.getWebToken() == null || typeof this.getWebToken().expires_at === "undefined"){
        return false;
    }
    
    try {
      const expired = Date.now() >= this.getWebToken().expires_at;
      //console.log('isAuthenticated.expired: ' + expired);
      return !expired;
    } catch (err) {
      return false;
    }
  }  

  isContextoSelecionado(): Boolean { 
    //console.log('this.getContexto: ' + this.getContexto());
    if(this.getContexto() == null){
        return false;
    }
    return (typeof this.getContexto().database !== "undefined" && typeof this.getContexto().entity !== "undefined");
  }  

  isLicencaSelecionada(): Boolean { 
    //console.log('this.getContexto: ' + this.getContexto());
    if(this.getLicenca() == null){
        return false;
    }
    return (typeof this.getLicenca().id !== "undefined");
  }  


  isUserAcessActive(): Boolean { 
    //console.log('this.isUserAcessActive: ' + this.getUserAcess());
    if(this.getUserAcess() === null){
        return false;
    }
    return (typeof this.getUserAcess() !== "undefined");
  }  

  public getWebToken() :WebToken{
    const dataLoad = localStorage.getItem('token');
    if (dataLoad) {
      //Converter o JSON salvo para a lista
      let webtoken: WebToken = JSON.parse(dataLoad);
      //console.log(webtoken.access_token);
      return webtoken;
    }
    return null;
  }

  public getUserAcess() :string{
    const licenca = this.getLicenca();
    if (licenca) {
      //Converter o JSON salvo para a lista
      //console.log(webtoken.access_token);
      return licenca.id.toString();
    }
    return null;
  }

  public getLicenca() :ItemSelecaoContextoQuasar{
    const dataLoad = localStorage.getItem('licenca');
    if (dataLoad) {
      //Converter o JSON salvo para a lista
      let licenca: ItemSelecaoContextoQuasar = JSON.parse(dataLoad);
      //console.log(webtoken.access_token);
      return licenca;
    }
    return null;
  }

  fetchData<T>(data: IHttpRequestApi): Observable<T> {
    let urlApi = this.getApi(data.servico);
    // console.log(
    //    "Calling service : " + urlApi + data.endPoint
    // );


    if(data.authentication && !this.checkCredentials()){
      //Usuario nao logado;
      return throwError(new HttpErrorResponse({ error: 'O usuário não foi autenticado!', status: 401 }));
    }

    if(data.useracess && !this.isUserAcessActive()){
      return throwError(new Error('Favor selecionar o contexto!'));
    }

    if(data.contexto && !this.isContextoSelecionado()){
      return throwError(new Error('Favor selecionar o contexto!'));
    }


    try {
      switch(data.method)
      {
        case (HttpMethodApi.GET):
          return this.Get<T>(urlApi, data).pipe(
            catchError((error) => {
              return throwError(error);
          })
          );
        case (HttpMethodApi.PUT):
          return this.Put<T>(urlApi, data).pipe(
            catchError((error) => {
              return throwError(error);
          })
          );
        case (HttpMethodApi.POST):
          return this.Post<T>(urlApi, data).pipe(
            catchError((error) => {
                return throwError(error);
            })
          );
        case (HttpMethodApi.DELETE):
          return this.Delete<T>(urlApi, data).pipe(
            catchError((error) => {
              return throwError(error);
          })
          );
        default:
          return this.Get<T>(urlApi, data).pipe(
            catchError((error) => {
              return throwError(error);
          })
          );
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  getAuthorizationHaders(servico: ServicoCode): HttpHeaders | { [header: string]: string | string[]; } {
    if (servico == ServicoCode.SEGURANCA || servico == ServicoCode.ARBOR) {
      //Quasar
      return { Authorization: `Bearer ${this.getWebToken().wsLoginQuasar.usuario.token}`};
    } else {      
      //Betha
      return { 'Content-Type': 'application/json',
                Authorization: `Bearer ${this.getAuthorization()?.accessToken}`,
               'User-access': `${this.getAuthorization()?.userAccess}`};
    }    
   }

  /**
 * GET request
 * @param {string} api URL da API
 * @param {IRequestOptions} options options of the request like headers, body, etc.
 * @returns {Observable<T>}
 */
  public Get<T>(api: string, options: IHttpRequestApi): Observable<T> {
    return this.httpClient.get<T>(api + options.endPoint, {
      headers: (options.headers === null && options.authentication) ? this.getAuthorizationHaders(options.servico) : options.headers,
      params: options.params,
      observe: options.observe,
      responseType: options.responseType
    });
  }


  /**
   * POST request
   * @param {string} api URL da API
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Post<T>(api: string, options?: IHttpRequestApi): Observable<T> {
    return this.httpClient.post<T>(api + options.endPoint, options.body, {
      headers: (options.headers === null && options.authentication) ? this.getAuthorizationHaders(options.servico) : options.headers,
      observe: options.observe,
      responseType: options.responseType
    });  
  }

  /**
   * PUT request
   * @param {string} api URL da API
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Put<T>(api: string, options?: IHttpRequestApi): Observable<T> {
    return this.httpClient.put<T>(api + options.endPoint, options.body, {
      headers: (options.headers === null && options.authentication) ? this.getAuthorizationHaders(options.servico) : options.headers,
      observe: options.observe,
      responseType: options.responseType
    });  
  }

  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Delete<T>(api: string, options?: IHttpRequestApi): Observable<T> {
    console.log('Delete '+api + options.endPoint);
    return this.httpClient.delete<T>(api + options.endPoint, {
      headers: (options.headers === null && options.authentication) ? this.getAuthorizationHaders(options.servico) : options.headers,
      params: options.params,
      observe: options.observe,
      responseType: options.responseType
    });  
  }
}

