<div class="col-md-9">
    <div class="hidden-lg hidden-md">&nbsp;</div>
    <div class="row">
        <div class="col-md-2 col-sm-2 col-xs-2">
            <button class="btn btn-success btn-add btn-sm btn-default" (click)="open(contentaddusuario)" title="Adiciona um usuário">Usuário</button>
        </div>
        <div class="col-md-10 col-sm-10 col-xs-10 text-right">
            <div class="form-inline">
                <div class="input-group pull-right" style="margin-left: 4px;">
                    <input class="form-control input-sm search-field ng-pristine ng-valid ng-empty ng-touched" type="text" id="pesquisar" data-ng-model="dashboardCtrl.searchQuery" data-bf-search-on-enter="dashboardCtrl.search()" placeholder="Pesquisar" style="">
                </div>
            </div>
            <button type="button" class="btn btn-default btn-sm" title="Atualizar" data-ng-click="dashboardCtrl.att()" data-bf-loading="dashboardCtrl.trackers.att">
                <i class="icon fa fa-refresh"></i>
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" data-bf-loading="dashboardCtrl.trackers.loading">
            <!-- Usuários -->
            <div class="row" style="">
                <div class="col-md-12">
                    <ul class="filtrar-por">
                        <li ng-class="{'active': !dashboardCtrl.onlyConnectedAccess}" class="active">
                            <a href="#gerenciando" data-ng-click="dashboardCtrl.filterForAllAccess()">
                                Todos
                            </a>
                        </li>
                        <li ng-class="{'active': dashboardCtrl.onlyConnectedAccess}">
                            <a href="#gerenciando" data-ng-click="dashboardCtrl.filterForConnectedAccess()">
                                Ativos
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="feature-highlight">
                <table id="user" class="table ellipsis" ng-class="{'table-condensed' : dashboardCtrl.lineHeight == dashboardCtrl.lineHeights.CONDENSADA}" ng-if="!dashboardCtrl.isEntitiesEmptyList() &amp;&amp; dashboardCtrl.view === 'user'" style="">
                    <thead>
                        <tr>
                            <th width="30" class="text-center">
                                <div class="dragtable-drag-handle"></div>
                                <div class="bth-checkbox">
                                    <input id="chboxAllUsr" data-ng-model="dashboardCtrl.checkAllItens" type="checkbox" ng-click="dashboardCtrl.checkAll()" class="ng-pristine ng-untouched ng-valid ng-empty">
                                    <label for="chboxAllUsr">
                                        &nbsp;
                                    </label>
                                </div>
                            </th>
                            <th>
                                <a href="" data-ng-click="dashboardCtrl.setOrderBy('user')">Nome <i data-ng-class="dashboardCtrl.getClassIconOrderBy('user')" class="fa fa-sort-down pull-right"></i></a>
                            </th>
                            <th width="150" class="optional-col">
                                <a href="" data-ng-click="dashboardCtrl.setOrderBy('createAt')">Autorizado
                                    em <i data-ng-class="dashboardCtrl.getClassIconOrderBy('createAt')" class="fa fa-sort pull-right"></i></a>
                            </th>
                            <th width="150" class="optional-col">
                                <a href="" data-ng-click="dashboardCtrl.setOrderBy('expiresIn')">Válido até
                                    <i data-ng-class="dashboardCtrl.getClassIconOrderBy('expiresIn')" class="fa fa-sort pull-right"></i></a>
                            </th>
                            <th width="120" class="optional-col">
                                <span>Situação</span>
                            </th>
                            <th width="80">
                                <span>Ações</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let access of licencas" data-ng-class="{'table-row-checked' : access.checked}" data-bf-undo-group="" class="" style="">
                            <td class="text-center ">
                                <div class="bth-checkbox">
                                    <input [disabled]="!access.accepted" id="chbox0" type="checkbox" data-ng-model="access.checked" class="ng-pristine ng-untouched ng-valid ng-empty">
                                    <label for="chbox0">
                                        &nbsp;
                                    </label>
                                </div>
                            </td>

                            <td class="card-container">
                                <a href="" data-ng-click="dashboardCtrl.setUrlUserPic(access)" bf-popover="dashboardCtrl.getUrlPopover()" data-bf-tooltip="Ver usuário" data-placement="right" data-original-title="" title="">
                                    <i class="fa fa-user"></i>
                                </a>
                                <span class="username-label" style="white-space: nowrap;"> {{access.username}}</span>
                                <br>
                                <span class="username"></span>{{access.user}} 
                                <div style="display: flex;"></div>
                            </td>
                            <td class="optional-col">
                                <span>{{access.createAt | date : 'dd/MM/yyyy HH:mm'}}</span>
                                <br>
                                <small class="tx__gray--d10">
                                    por <span class="username" *ngIf="access.createBy">{{access.createBy}}</span><!---->
                                </small>
                            </td>
                            <td class="optional-col">
                                <span ng-show="access.expires" class="ng-hide">{{access.expiresIn}}</span>
                            </td>
                            <td class="optional-col">
                               <span *ngIf="!access.connected &amp;&amp; !access.lastActivity" title="Sem informações da última atividade">
                                    <i class="fa fa-circle tx__gray" aria-hidden="true"></i> Desconectado
                                </span>
                            </td>
                            <td class="text-center ">
                                <div class="btn-group">
                                    <li style="list-style: none!important;">
                                        <!-- <a href="#gerenciando" (click)="editAccess(access)" *ngIf="access.admin" title="Editar acesso">
                                            <i class="fa fa-fw fa-pencil"></i>
                                        </a> -->
                                        <a href="#gerenciando" (click)="open(content)" title="Remover acesso">
                                            <i class="fa fa-fw fa-trash-o"></i>
                                        </a>        
                                    </li>
                                </div>
                                <ng-template #content let-c="close" let-d="dismiss" class="modal-content">
                                    <div class="modal-header">
                                        <h3 class="modal-title" id="modal-basic-title">Atenção
                                        </h3>
                                        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="d('Cross click')">
                                            <i class="fa fa-fw fa-close"></i>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>O acesso do usuário <b>{{access.username}}</b> será removido!</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button class="btn btn-primary btn-sm btn-default" (click)="removeAccess(access)" >
                                            REMOVER ACESSO
                                        </button>
                                        <button class="btn btn-danger btn-sm btn-default" (click)="d('Cross click')" >
                                            CANCELAR
                                        </button>                                                 
                                    </div>
                                </ng-template>                                
                            </td>
                        </tr><!---->
                    </tbody>
                </table><!---->
            </div>
        </div>
    </div>
</div>

<ng-template #contentaddusuario let-c="close" let-d="dismiss" class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Autorizando usuário</h3>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="d('Cross click')">
            <i class="fa fa-fw fa-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="bth-passoapasso">
            <ul>
                <li class="active" >
                    <a href="#gerenciando"><i class="fa fa-fw fa-user"></i> Localizar usuário</a>
                </li>

                <li class="inactive" >
                    <a href="#gerenciando"><i class="fa fa-fw fa-key"></i> Gerenciar permissão</a>
                </li>
            </ul>
        </div>
        <div style="margin: 20px 0" class="div">
            <div class="row"> 
                <div class="col-md-6 col-md-offset-3">
                    <label>Usuário</label>
                    <div class="input-group">
                        <input [(ngModel)]="pesquisaUsuario" (keyup.enter)="searchUsuario()" type="text" class="form-control ng-pristine ng-valid ng-empty ng-touched" placeholder="Encontre pessoas pelo nome de usuário, CPF, e-mail ou celular" >
                        <div class="input-group-btn">
                            <button (click)="searchUsuario()" type="button" class="btn btn-default" title="Pesquisar" >
                                <i class="icon fa fa-search"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="bth-card__header header--card" *ngIf="!usuarioAutorizar && isUsuarioNaoEncontrado()">
                        <br>O usuário não encontrado.
                    </div>
                    <!-- Termo card -->
                    <div class="col-md-6 col-md-offset-3"  *ngIf="usuarioAutorizar">
                        <div class="bth-card bth-card--bordered">
                            <div class="bth-card__header header--card" *ngIf="usuarioAutorizar">
                                <div class="alert alert-warning ng-hide" *ngIf="isUsuarioJaCadastrado()">
                                    O usuário já possui um acesso criado.
                                </div>
                                <div class="row align-middle mb-0">
                                    <div class="col-md-2">
                                        <div class="photo-on-list" style="background-size: 110%; width: 60px; height: 60px; border-radius: 50%;"></div>
                                    </div>
                                    <div class="col-md-10">
                                        <div class="ellipsis">
                                            <strong>{{usuarioAutorizar.name}}</strong>
                                            <br>
                                            <small class="text-muted username">{{usuarioAutorizar.id}} </small>
                                            <div class="bth-switch mt-8" *ngIf="!isUsuarioJaCadastrado()">
                                                <input id="checkboxAdmin" type="checkbox" ng-model="accessCadCtrl.access.admin" class="ng-pristine ng-untouched ng-valid ng-empty">
                                                <label for="checkboxAdmin">Administrador</label>
                                            </div>
                                        </div>
                                        <!---->
                                    </div>
                                </div>
                            </div>
                            <div class="bth-card__body" *ngIf="usuarioAutorizar">
                                <div>
                                    <div class="title-term--card">
                                        <strong>Termo de responsabilidade</strong>
                                    </div>
                                    <span>
                                        Nesse ato concedo acesso ao sistema , para o usuário
                                        @{{usuarioAutorizar.id}}, para fins de apuração dos dados informados no sistema.
                                    </span>
                                </div>
                                <div class="bth-checkbox mt-16">
                                    <input id="chbox" type="checkbox" [checked]="isUsuarioJaCadastrado()" [(ngModel)]="termAccepted" class="ng-valid ng-not-empty ng-dirty ng-valid-parse ng-touched" style="" [disabled]="isUsuarioJaCadastrado()">
                                    <label for="chbox">
                                        Li e aceito o termo<br>
                                        de responsabilidade
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>                
            </div>
        </div>        
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-default btn-primary" *ngIf="usuarioAutorizar && !isUsuarioNaoEncontrado() && !isUsuarioJaCadastrado()" (click)="addAccess()">
            SALVAR
        </button>
        <button class="btn btn-danger btn-sm btn-default" (click)="d('Cross click')">
            CANCELAR
        </button>                                                 
    </div>
</ng-template>

