import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Authorization, AuthorizationConfig } from '@betha-plataforma/estrutura-componentes';
import { ApplicationHttpClient } from 'src/app/lib/apiHttp';
import { HttpMethodApi, ServicoCode } from 'src/app/lib/apiQuasarHttp';
import { HttpParams } from '@angular/common/http';
import { AcessoUsuario } from 'src/app/modelo/acessousuario';
import { Licenca } from 'src/app/modelo/licencas';
import { ConteudoPesquisa } from 'src/app/modelo/conteudopesquisa';

@Injectable({
  providedIn: 'root'
})

export class AutorizacoesService {
  constructor(private httpClient: ApplicationHttpClient) {
  }
  
  servico: ServicoCode = ServicoCode.AUTORIZACOES;

  public getAuthorization(): Authorization {
   return this.httpClient.getAuthorization();
  }

  async handleUnauthorizedAccess(): Promise<void> {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, 3500);
    });
  }

  getInformacaoDoUsuario(): Observable<any> {
    return this.httpClient.fetchData({ 
      endPoint: 'users/@me', 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      params: new HttpParams({ 
        fromObject: { 'access_token': this.httpClient.getAuthorization()?.accessToken } 
      }),
      useracess:false,
      servico: this.servico});
  }

  getAcessosDoUsuario<T>(): Observable<T> {
    return this.httpClient.fetchData({ 
      endPoint: 'suite/users/@me/access', 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      responseType: 'json',
      useracess:false,
      servico: this.servico});
  }

  getUsuariosComAcessoAoSistema<T>(): Observable<ConteudoPesquisa<T>> {
    return this.httpClient.fetchData({ 
      endPoint: 'management/access', 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      params: new HttpParams({ 
        fromObject: { 
          'limit': '1000',
          'offset': '0'
        } 
      }),    
      useracess:true,  
      servico: this.servico });
  }

  concederAcessoUsuario(acessoUsuario :AcessoUsuario): Observable<any> {
    return this.httpClient.fetchData({ 
      endPoint: 'management/access', 
      authentication: true,
      useracess:true,
      method: HttpMethodApi.POST, 
      body: JSON.stringify(acessoUsuario),
      headers: null,
      params: null,  
      servico: this.servico});    
  }

  removerAcessoUsuario(idAcesso :number | string): Observable<any> {
    return this.httpClient.fetchData({ 
      endPoint: 'management/access/'+idAcesso, 
      authentication: true,
      method: HttpMethodApi.DELETE, 
      body: null,
      headers: null,
      params: null,  
      useracess:true,
      servico: this.servico});    
  }

  getUsuarios(): Observable<any> {
    return this.httpClient.fetchData({ 
      endPoint: 'suite/users/@me', 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      useracess:true,
      servico: this.servico});
  }

  //Consultar usuários por filtros
  /**
   *   --url 'https://plataforma-autorizacoes.betha.cloud/user-accounts/v0.1/api/management/users?query=fmazzuco&allowMultiple=true'
   *   --header 'Content-Type: application/json' \
   *   --header 'Authorization: Bearer {TOKEN}' \
   *   --header 'User-access: ${USER_ACCESS}
   */
  getUsuariosFiltros(query: String): Observable<any> {
    return this.httpClient.fetchData({ 
      endPoint: 'management/users?query='+{query}, 
      authentication: true,
      method: HttpMethodApi.GET, 
      body: null,
      headers: null,
      useracess:true,
      servico: this.servico});
  }


  logout(){
    this.httpClient.limparLocalStorage();
  }
}
